import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete Icon
import Typography from "@mui/material/Typography";
import { useState } from "react";

import ReusableDialog from "@/shared/components/ReusableDialog";
import TertiaryButton from "@/shared/components/TertiaryButton";

import deleteResource from "../services/deleteResource";

export default function DeleteResourceButton({
  onCatalogueAndCostRefetch,
  rowSelection,
}: {
  onCatalogueAndCostRefetch: () => void;
  rowSelection: string[];
}) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    onCatalogueAndCostRefetch();
    setModalOpen(false);
  };
  const resourceDeletion = async () => {
    try {
      await Promise.all(rowSelection.map((id) => deleteResource(id)));
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Error deleting resource: ${error.message}`);
      } else {
        throw new Error("An unknown error occurred while deleting resource.");
      }
    }
  };

  const handleResourceDeletion = async () => {
    await resourceDeletion();
    setModalOpen(false);
    onCatalogueAndCostRefetch();
  };

  return (
    <>
      <TertiaryButton
        sx={{
          marginRight: "5px",
        }}
        icon={<DeleteIcon fontSize="small" />}
        onClick={handleOpen}>
        Delete
      </TertiaryButton>
      <ReusableDialog
        isOpen={isModalOpen}
        title="Delete Selected Outcome"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleResourceDeletion}
        confirmButtonText="Delete"
        confirmButtonType="error"
        size="small">
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Are you sure you want to delete the selected outcome?
        </Typography>
      </ReusableDialog>
    </>
  );
}
